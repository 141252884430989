import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ProtfolioDetails from "@/components/protfolio-details";
import RelatedPortfolio from "@/components/related-portfolio";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import { PortfolioDetailsData } from "@/data";

import { useQueryParam } from "gatsby-query-params";

const PortfolioDetailsPage = () => {
  const page = useQueryParam("page");
  const data = PortfolioDetailsData[page]?PortfolioDetailsData[page]:null;
  console.info(page, data);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle='Case Study'>
          <HeaderOne />
          <PageBanner title="Case Study" name="Portfolio" page='portfolio' />
          <ProtfolioDetails data={data} />
          <RelatedPortfolio />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default PortfolioDetailsPage;
